<template>
  <div class="home">
    <div class="content">
     <template v-if="dataList.length">
      <el-card class="el-card" shadow="never" v-for="(item, i) in dataList"
               :key="i">
        <div class="other-system" v-if="item.children&&item.children.length>0">
          <h4>{{ item.menuName }}</h4>
          <el-row class="ot-systems" :gutter="20">
           <template>
            <el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="4"  v-for="(m, index) in item.children" :key="index">
              <el-tooltip v-if="m.isSso===1" content="支持单点登录" placement="top">
								<div class="ot-system-item sso_mark" >
									<a @click="proxyLogin(m)">
										<div class="i-div">
											<img class="ot-system-item_img" :src="require(`../../assets/image/system${m.iconUrl}`)" alt/>
										</div>
										<div class="i-span">{{ m.menuName }}</div>
									</a>
                  <span @click.stop="collectHandle(m)" :class="`icon-item-collect ${m.favorites?'icon-item-collect-active':''}`"><i :class="` ${m.favorites?'el-icon-star-on':'el-icon-star-off'}`"></i></span>
								</div>
              </el-tooltip>
              <div v-else  class="ot-system-item">
                <a @click="proxyLogin(m)">
                  <div class="i-div">
                    <img class="ot-system-item_img" :src="require(`../../assets/image/system${m.iconUrl}`)" alt/>
                  </div>
                  <span class="i-span">{{ m.menuName }}</span>
                </a>
                <span @click.stop="collectHandle(m)" :class="`icon-item-collect ${m.favorites?'icon-item-collect-active':''}`"><i :class="` ${m.favorites?'el-icon-star-on':'el-icon-star-off'}`"></i></span>
              </div>
            </el-col>
           </template>
          </el-row>
        </div>
         <div class="other-system" v-else-if="item?.menuName==='我的收藏'">
          <h4>{{ item?.menuName }}</h4>
          <div style="height: 60px;width: 100%;line-height: 60px;font-size: 14px;padding-left: 4px;color: #5e6d82;font-weight: 900;">请将您常用的系统添加至我的收藏
          </div>
         </div>

      </el-card>
     </template>
     <template v-else>
        <h6 style="margin: 0 auto;width: 100%;color: #909399;text-align: center;font-size: 14px;">暂无数据</h6>
     </template>
    </div>

    <!--FBI开发账号弹框-->
    <el-dialog
      title="请选择对应的FBI开发账号"
      :visible.sync="fbiDevelopVisible"
      width="50%"
      @close="fbiDevelopVisible = false"
    >
      <div style="overflow: hidden;margin: 0 20%;">
        <el-row :gutter="20" justify="center">
          <el-col :xs="24" :sm="24" :md=24 :lg="12" :xl="12"
                  v-for="(item, index) in fbiDataListDev" :key="index">
            <div style="margin: 5px 5%;">
              <el-button style="width: 100%;" size="mini"  @click="proxyLoginFbi(1, item)">
                {{ item.menuName }}
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <!--FBI查看账号弹框-->
    <el-dialog
      title="请选择对应的FBI查看账号"
      :visible.sync="fbiViewVisible"
      width="50%"
      @close="fbiViewVisible = false"
    >
      <div style="overflow: hidden;margin: 0 20%;">
        <el-row :gutter="20" justify="center">
          <el-col :xs="24" :sm="24" :md=24 :lg="12" :xl="12"
                  v-for="(item, index) in fbiDataListLook" :key="index">
            <div style="margin: 5px 5%;">
              <el-button style="width: 100%;" size="mini" @click="proxyLoginFbi(2,item)">
                {{ item.menuName }}
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { 
  getQueryUserMenuPermissionAPI,
  getQueryUserPermissionAPI,
  getQuerySSOTokenAPI,
  deleteCancelSysUserFavorites,
  addCancelSysUserFavorites
 } from "@/api/home.js";
 import {
  getItem,
  setItem
} from '@/utils/storage'
import { flatToTreeHome } from "@/utils/commen.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dataList:[],//-列表
      fbiDataListDev:[],
      fbiDataListLook:[],
      fbiDevelopVisible: false,
      fbiViewVisible: false,
    };
  },
  created() {
    this.getQueryUserMenuPermissionAPILits()
  },
  methods: {
  async  getQueryUserMenuPermissionAPILits(){ //-列表数据
      try {
        let res=await getQueryUserMenuPermissionAPI({menuType:'S'});
        this.dataList=[]
        this.dataList=flatToTreeHome(res.data)
      } catch (error) {
        this.$message.error(`${error||'请求失败!'}`);  
      }
    },
    handleRespose(res,successMessage,errorMessage){
            if(res.code===0){
            this.$message.success(successMessage);  
            this.getQueryUserMenuPermissionAPILits()
            }else{
              this.$message.error(res.message ||errorMessage);  
            }
          },
  async  collectHandle(row){
      try {
        let res
         if(row.favorites){
          res=await deleteCancelSysUserFavorites({menuCode:row.menuCode})
          this.handleRespose(res,'取消收藏成功!','操作失败!')
         }else{
          res=await addCancelSysUserFavorites({menuCode:row.menuCode})
          this.handleRespose(res,'添加收藏成功!','操作失败!')
         }
       } catch (error) {
        this.$message.error(error||'操作失败!');   
       }
    },
    newWindow(url) {
      let isMobile = false;
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        isMobile = true;
      }
      if (isMobile) {
        location.href = url;
      } else {
        window.open(url);
      }
    },
    async proxyLogin(m) {
      if (m.isSso===1) {
        let username=getItem('ssoUser')?.username||"";
        if (m.menuCode == 'fbi_develop') { //-FBI报表-开发
          let res=await getQueryUserPermissionAPI({readonly:false,username:username})
          if(res.code===0){
            this.fbiDevelopVisible = true;
            this.fbiDataListDev=res.data
          }else{
            this.$message({
                type: 'error',
                message: `${res.message||'操作失败!'}`
            });  
          }
        } else if (m.menuCode == 'fbi_view') { //FBI报表-查看
          let res=await getQueryUserPermissionAPI({readonly:true,username:username})
          if(res.code===0){
            this.fbiViewVisible = true;
            this.fbiDataListLook = res.data;
          }else{
            this.$message({
                type: 'error',
                message: `${res.message||'操作失败!'}`
            });  
          }
        }else if(m.menuCode == 'opc'){
          let token=getItem('ssoToken');
          let url=m.systemUrl+'/admin/index.php?route=api/user/sso_login&ssoToken='+token
          this.newWindow(url);
        }else if(m.menuCode == 'odoo'){
          let token=getItem('ssoToken');
          let url=m.systemUrl+'/sso/login?ssoToken='+token
          this.newWindow(url);
        }else if(m.menuCode == 'xcrm'){
          let token=getItem('ssoToken');
          let url=m.systemUrl+'#/index/work-place?ssoToken='+token
          this.newWindow(url);
        }else if(m.menuCode == 'devops'){
          let token=getItem('ssoToken');
          let url=m.systemUrl+'?accessToken='+token+'&ssoFlag=1'
          this.newWindow(url);
        }else if(m.menuCode == 'pte'){
          let token=getItem('ssoToken');
          let url=m.systemUrl+'login?ssoToken='+token
          this.newWindow(url);
        }else {
          let res = await generateToken({system: m.menuCode});
          if (res.status == 200) {
            this.newWindow(res.data.proxy_login_url);
          } else {
            this.newWindow(m.systemUrl);
          }
        }
      } else {
        this.newWindow(m.systemUrl);
      }
    },

    async proxyLoginFbi(type = 2, row) {
      let res = await getQuerySSOTokenAPI({username: row.menuCode});
      if (res.code === 0) {
        let proxy_login_url = `https://fbi.ehsy.com/webroot/decision?ssoToken=${res.data}`
        this.newWindow(proxy_login_url);
      } else {
        this.newWindow(systemSingle.url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  font-size: 16px;
  background: $color-bg-lg !important;

  a {
    text-decoration: none;
  }

  .content {
    margin: 0 3%;
    ::v-deep .el-carousel__container {
      margin: 0 auto;
      width: 100%;
      height: 32vw;

      .headImg {
        width: 100%;
      }
    }
    ::v-deep .el-card__body {
      padding: 0 20px !important;
    }

    .company-system {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;

      .company {
        width: 65%;
        color: #5d5d5d;

        .title {
          margin-bottom: 20px;
          font-size: 18px;
          font-weight: bold;
          color: #160000;
        }

        .company-intro-img {
          margin-right: 20px;
          width: 237px;
          height: 143px;
        }
      }

      .system {
        width: 30%;

        .title {
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: bold;
          color: #160000;
        }

        .system-item {
          height: 43px;
          color: $color-theme-dark;
          border-bottom: 1px solid #bbbbbb;
          box-shadow: 0 1px #f2f2f2;
          cursor: pointer;
          display: flex;
          align-items: center;

          .system-item-icon {
            margin-right: 5px;
          }
        }
      }
    }

    .other-system {
      .title {
        margin: 30px 0 10px;
        color: #000;
        font-weight: bold;
      }

      .ot-systems {
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;

        .ot-system-item {
          height: 30px;
          line-height: 30px;
          margin: 8px 0;
          background: #fff;
          padding: 10px 10px;
          word-break: break-all;
          cursor: pointer;
          border-radius: 5px;
          box-shadow: 2px 3px 5px -2px #d3d3d3;
          display: flex;
          a{
            width: 80%;
          }
        }
        .icon-item-collect{
          float: right;
        }
        .icon-item-collect-active{
          i{
            color: #FF6600;
            font-size: 20px;
          }
        }
				.ot-system-item-devlop {
					.i-div, .ot-system-item_img, .i-span {
						width: auto !important;
					}
				}

        .sso_mark {
          background-image: url("../../assets/image/system/subscript/sso.png");
          background-repeat: no-repeat;
          background-position-x: right;
          background-position-y: top;
          background-size: 25px;
        }

        .ot-system-item_img {
          width: 30px;
          height: 30px;
        }
      }

      .i-div {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        float: left;
      }

      .i-span {
        float: left;
        margin-left: 5px;
        font-size: 12px;
        width: calc(100% - 40px);
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .el-card {
      margin: 10px auto;
      border: none !important;
      box-shadow: none !important;
      background: #f1f5f9;

      .clearfix {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .bottom {
      margin: 30px 0 10px;
      text-align: center;

      a {
        margin: 0 20px;
        color: #4d4d4d;
        text-decoration: none;
      }
    }
  }
}
</style>
