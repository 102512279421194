import axios from '../utils/axios'

let baseURL = '/sysMenu'

// 列表
export function getQueryUserMenuPermissionAPI(params) {
    return axios.get(baseURL+'/queryUserMenuPermission', {params})
}
// 帆软登录账号选择
export function getQueryUserPermissionAPI(params) {
    return axios.get('/funRuan/queryUserPermission', {params})
}
// 帆软账号获取ssoToken
export function getQuerySSOTokenAPI(params) {
    return axios.get('/funRuan/querySSOToken', {params})
}
// 取消收藏
export function deleteCancelSysUserFavorites(params) {
    return axios.post(`/sysUserFavorites/cancelSysUserFavorites/${params.menuCode}`,)
}
// 添加收藏
export function addCancelSysUserFavorites(params) {
    return axios.post(`/sysUserFavorites/addSysUserFavorites`,params)
}